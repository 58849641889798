import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { GeneralService } from '@app/core/services/general.service';
import { VendorService } from '@app/core/services/vendor.service';
import { SubscriptionDisposerComponent } from 'src/app/shared/helpers/subscription-disposer';

import {
  getErrorReason,
  isValidFile,
} from '../ticket-chat-configuration-modal/file-upload-util';

@Component({
  selector: 'view-files-modal',
  templateUrl: './view-files-modal.component.html',
})
export class ViewFilesModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit
{
  @Output() filesUpdated = new EventEmitter<{
    files: File[];
    action: 'add' | 'remove';
  }>();

  allowedFileExt =
    'png|.jpeg|.jpg|.doc|.docx|.pdf|.mp4|.mov|.avi|.wmv|.webm|.ogg|.mpeg|.3gpp';
  maxFileSize = 1024 * 1024 * 5;

  originalFiles: File[] = []; // Hold the files passed in from the parent
  hasChanges: boolean = false; // Track whether there are any changes


  files: File[] = []; // Array to hold the passed files

  constructor(
    public dialogRef: DialogRef<any>,
    private vendorService: VendorService,
    private generalService: GeneralService,

    @Inject(DIALOG_DATA)
    public data: {
      files: File[];
    }
  ) {
    super();
    console.log('data');
    console.log(data);

    if (data?.files) {
      this.originalFiles = [...data.files]; // Store the original files

      this.files = [...data.files]; // Initialize local files
      // this.files = data.files;
    }
  }

  ngOnInit(): void {}

  closeModal(data?: any): void {
    this.dialogRef.close(data);
  }

  formatFileSize(size: number): string {
    if (size < 1024) return size + ' bytes';
    if (size < 1048576) return (size / 1024).toFixed(2) + ' KB';
    return (size / 1048576).toFixed(2) + ' MB';
  }

  removeFile(file: File): void {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
      // this.files = [...this.files];
      // this.vendorService.setFiles(this.files); // Update the shared file list
      this.checkForChanges(); // Check if there are any changes
    }
  }
  
  handleFileInput(event: any): void {
    if (this.maximumFileValidation(event)) {
      return;
    }

    const files = event.target.files;

    if (files && files.length > 0) {
      const validFiles: File[] = [];

      for (const file of files) {
        // Validate file
        const fileDetail = isValidFile(
          file,
          this.maxFileSize,
          this.allowedFileExt,
          ''
        );

        if (fileDetail.validity) {
          // Check for duplicates based on name and size
          const isDuplicate = this.files.some(
            (existingFile) =>
              existingFile.name === file.name && existingFile.size === file.size
          );

          if (isDuplicate) {
            this.generalService.displayError(
              `The file "${file.name}" is already added.`
            );
            continue;
          }

          validFiles.push(file);
        } else {
          const reason = getErrorReason(fileDetail.fileData);
          this.generalService.displayError(reason);
        }
      }

      // Merge valid files into the current list
      this.files = [...this.files, ...validFiles];

      // Trigger UI update
      this.files = [...this.files];

      this.checkForChanges(); // Check if there are any changes

    } else {
      this.generalService.displayError('Please select files.');
    }

    // Reset the file input value
    event.target.value = '';
  }

  maximumFileValidation(event: any): boolean {
    if (
      this.files.length > 10 ||
      this.files.length + event.target.files.length > 10
    ) {
      this.generalService.displayError('Maximum 10 files are allowed');
      return true;
    }
    return false;
  }

  updateFiles(): void {
    // send data to prev comoponet
    this.vendorService.setFiles(this.files); // Update the shared file list
    this.dialogRef.close(); // Close the modal
  }

   // Check if there are changes in the file list.
   checkForChanges(): void {
    this.hasChanges =
      this.files.length !== this.originalFiles.length ||
      this.files.some(
        (file, index) =>
          file.name !== this.originalFiles[index]?.name ||
          file.size !== this.originalFiles[index]?.size
      );
  }
}
