import { DEFAULT_DIALOG_CONFIG, DialogModule } from '@angular/cdk/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material Modules
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { DateRangePickerModule } from '@date-range-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TimePickerModule } from '@time-picker';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgSelect2Module } from 'ng-select2';

import { TooltipModule } from 'ng2-tooltip-directive';

// Configuration Modals

// Pipes
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { AllNotificationsComponent } from './components/all-notifications/all-notifications.component';
import { CheckListComponent } from './components/approval-checklist/check-list.component';
import { FileUploadComponent } from './components/common/file-upload/file-upload.component';
import { AddAdminSupportTicketModalComponent } from './components/configurations/add-admin-support-ticket-modal/add-admin-support-ticket-modal.component';
import { AddBankAccountModalComponent } from './components/configurations/add-bank-account-modal/add-bank-account-modal.component';
import { AddCustomInformationModalComponent } from './components/configurations/add-custom-information-modal/add-custom-information-modal.component';
import { AddNewTicketModalComponent } from './components/configurations/add-new-ticket-modal/add-new-ticket-modal.component';
import { AddPackageCustomSettingModalComponent } from './components/configurations/add-package-custom-setting-modal/add-package-custom-setting-modal.component';
import { AddProductVariantModalComponent } from './components/configurations/add-product-variant-modal/add-product-variant-modal.component';
import { AddServiceVariantModalComponent } from './components/configurations/add-service-variant-modal/add-service-variant-modal.component';
import { AddTeammatesModalComponent } from './components/configurations/add-teammates-modal/add-teammates-modal.component';
import { AdvOrderDetailsComponent } from './components/configurations/adv-order-details/adv-order-details.component';
import { AssignVariantImageModalComponent } from './components/configurations/assign-variant-image-modal/assign-variant-image-modal.component';
import { BankAccountDeleteOtpVerificationModalComponent } from './components/configurations/bank-account-delete-otp-verification-modal/bank-account-delete-otp-verification-modal.component';
import { BankDetailsModalComponent } from './components/configurations/bank-details-modal/bank-details-modal.component';
import { CommitChangesModalComponent } from './components/configurations/commit-changes-modal/commit-changes-modal.component';
import { CrosssellUpsellProductSelectionModalComponent } from './components/configurations/crosssell-upsell-product-selection-modal/crosssell-upsell-product-selection-modal.component';
import { CrosssellUpsellTagSelectionModalComponent } from './components/configurations/crosssell-upsell-tag-selection-modal/crosssell-upsell-tag-selection-modal.component';
import { DeActivateModalComponent } from './components/configurations/de-activate-modal/de-activate-modal.component';
import { EditCouponModalComponent } from './components/configurations/edit-coupon-modal/edit-coupon-modal.component';
import { EditVariantOptionModalComponent } from './components/configurations/edit-variant-option-modal/edit-variant-option-modal.component';
import { EmailConfigurationModalComponent } from './components/configurations/email-configuration-modal/email-configuration-modal.component';
import { HomePageRowConfigureModalComponent } from './components/configurations/home-page-row-configure-modal/home-page-row-configure-modal.component';
import { InfoTooltipModalComponent } from './components/configurations/info-tooltip-modal/info-tooltip-modal.component';
import { MobileVerifiedConfirmationModalComponent } from './components/configurations/mobile-verified-confirmation-modal/mobile-verified-confirmation-modal.component';
import { NewEmailAddressModalComponent } from './components/configurations/new-email-address-modal/new-email-address-modal.component';
import { PackageConfigurationModalComponent } from './components/configurations/package-configuration-modal/package-configuration-modal.component';
import { PasswordConfigurationModalComponent } from './components/configurations/password-configuration-modal/password-configuration-modal.component';
import { PayoutSuccessModalComponent } from './components/configurations/payout-success-modal/payout-success-modal.component';
import { ProceedWithPayoutModalComponent } from './components/configurations/proceed-with-payout-modal/proceed-with-payout-modal.component';
import { ReportTemplateSettingModalComponent } from './components/configurations/report-template-setting-modal/report-template-setting-modal.component';
import { RequestAdminToEditModalComponent } from './components/configurations/request-admin-to-edit-modal/request-admin-to-edit-modal.component';
import { RequestPayoutModalComponent } from './components/configurations/request-payout-modal/request-payout-modal.component';
import { RequestPayoutSentModalComponent } from './components/configurations/request-payout-sent-modal/request-payout-sent-modal.component';
import { FileTypePipe } from './components/configurations/ticket-chat-configuration-modal/file-type.pipe';
import { TicketChatConfigurationModalComponent } from './components/configurations/ticket-chat-configuration-modal/ticket-chat-configuration-modal.component';
import { TicketInvoiceRequestModalComponent } from './components/configurations/ticket-invoice-request-modal/ticket-invoice-request-modal.component';
import { TicketUploadTaxInvoiceModalComponent } from './components/configurations/ticket-upload-tax-invoice-modal/ticket-upload-tax-invoice-modal.component';
import { TooltipComponent } from './components/configurations/tooltip/tooltip.component';
import { UpdateMobileModalComponent } from './components/configurations/update-mobile-modal/update-mobile-modal.component';
import { VerifyEmailAddressModalComponent } from './components/configurations/verify-email-address-modal/verify-email-address-modal.component';
import { VerifyEmailSuccessModalComponent } from './components/configurations/verify-email-success-modal/verify-email-success-modal.component';
import { ReportsFilterComponent } from './components/filter/reports-filter/reports-filter.component';
import { ReviewStatusFilterComponent } from './components/filter/review-status-filter/review-status-filter.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NoRecordsComponent } from './components/no-records/no-records.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { InvoicePrintComponent } from './components/printables/invoice-print/invoice-print.component';
import { ShippingPrintComponent } from './components/printables/shipping-print/shipping-print.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AllowDecimalDirective } from './directives/allow-decimal.directive';
import { AlphanumericDirective } from './directives/alphanumeric.directive';
import { ConfirmationDialogDirective } from './directives/confirmation-dialog.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { OpenModalDirective } from './directives/open-modal.directive';
import { CodeFormatterPipe } from './pipes/code-formatter.pipe';
import { ConfigCurrencyPipe } from './pipes/config-currency.service';
import { ConfigDatePipe } from './pipes/config-date.pipe';
import { ConfigTimePipe } from './pipes/config-time.pipe';
import { CustomDateFormatPipe } from './pipes/custom-date-format.pipe';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { ViewFilesModalComponent } from './components/configurations/view-files-modal/view-files-modal.component';

const components = [
  HeaderComponent,
  FooterComponent,
  FormsModule,
  ReactiveFormsModule,
  NgxIntlTelInputModule,
  SidebarComponent,
  NgSelect2Module,
  AngularEditorModule,
  PasswordConfigurationModalComponent,
  AddTeammatesModalComponent,
  DeActivateModalComponent,
  EmailConfigurationModalComponent,
  PackageConfigurationModalComponent,
  AddCustomInformationModalComponent,
  EditCouponModalComponent,
  AddNewTicketModalComponent,
  TicketChatConfigurationModalComponent,
  TicketInvoiceRequestModalComponent,
  TicketUploadTaxInvoiceModalComponent,
  AddProductVariantModalComponent,
  EditVariantOptionModalComponent,
  AssignVariantImageModalComponent,
  ReportsFilterComponent,
  AddBankAccountModalComponent,
  AddServiceVariantModalComponent,
  AddPackageCustomSettingModalComponent,
  NewEmailAddressModalComponent,
  VerifyEmailAddressModalComponent,
  VerifyEmailSuccessModalComponent,
  ReportTemplateSettingModalComponent,
  CrosssellUpsellProductSelectionModalComponent,
  CrosssellUpsellTagSelectionModalComponent,
  RequestPayoutModalComponent,
  UpdateMobileModalComponent,
  MobileVerifiedConfirmationModalComponent,
  NoRecordsComponent,
  ReviewStatusFilterComponent,
  ShippingPrintComponent,
  BankDetailsModalComponent,
  BankAccountDeleteOtpVerificationModalComponent,
  RequestPayoutSentModalComponent,
  RequestAdminToEditModalComponent,
  AddAdminSupportTicketModalComponent,
  CommitChangesModalComponent,
  AllNotificationsComponent,
  ProceedWithPayoutModalComponent,
  PayoutSuccessModalComponent,
  AdvOrderDetailsComponent,
  TooltipComponent,
  ResetPasswordComponent,
  InvoicePrintComponent,
  HomePageRowConfigureModalComponent,
  InfoTooltipModalComponent,
  ViewFilesModalComponent
];
const COMMON_COMPONENTS = [FileUploadComponent];

const materialModules = [
  MatTableModule,
  MatPaginatorModule,
  MatExpansionModule,
  MatTableExporterModule,
];

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    EmailConfigurationModalComponent,
    PasswordConfigurationModalComponent,
    PackageConfigurationModalComponent,
    CheckListComponent,
    DeActivateModalComponent,
    AddTeammatesModalComponent,
    CustomDateFormatPipe,
    AllowDecimalDirective,
    NumbersOnlyDirective,
    ConfirmationDialogDirective,
    ConfigDatePipe,
    ConfigTimePipe,
    CodeFormatterPipe,
    ShippingPrintComponent,
    ConfigCurrencyPipe,
    NumberSuffixPipe,
    AddCustomInformationModalComponent,
    EditCouponModalComponent,
    AddNewTicketModalComponent,
    TicketChatConfigurationModalComponent,
    TicketInvoiceRequestModalComponent,
    TicketUploadTaxInvoiceModalComponent,
    AddProductVariantModalComponent,
    EditVariantOptionModalComponent,
    AssignVariantImageModalComponent,
    ReportsFilterComponent,
    AddBankAccountModalComponent,
    AddServiceVariantModalComponent,
    AddPackageCustomSettingModalComponent,
    NotificationsComponent,
    NewEmailAddressModalComponent,
    VerifyEmailAddressModalComponent,
    VerifyEmailSuccessModalComponent,
    ReportTemplateSettingModalComponent,
    CrosssellUpsellProductSelectionModalComponent,
    CrosssellUpsellTagSelectionModalComponent,
    RequestPayoutModalComponent,
    UpdateMobileModalComponent,
    MobileVerifiedConfirmationModalComponent,
    NoRecordsComponent,
    ReviewStatusFilterComponent,
    BankDetailsModalComponent,
    InfoTooltipModalComponent,
    BankAccountDeleteOtpVerificationModalComponent,
    RequestPayoutSentModalComponent,
    RequestAdminToEditModalComponent,
    AddAdminSupportTicketModalComponent,
    CommitChangesModalComponent,
    AllNotificationsComponent,
    ProceedWithPayoutModalComponent,
    PayoutSuccessModalComponent,
    AdvOrderDetailsComponent,
    TooltipComponent,
    ResetPasswordComponent,
    InvoicePrintComponent,
    ...COMMON_COMPONENTS,
    HomePageRowConfigureModalComponent,
    FileTypePipe,
    OpenModalDirective,
    AlphanumericDirective,
    ViewFilesModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelect2Module,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    TooltipModule,
    NgxDaterangepickerMd.forRoot({
      separator: ' - ',
      applyLabel: 'SAVE',
      cancelLabel: 'RESET',
      clearLabel: 'RESET',
    }),
    TimePickerModule,
    DragDropModule,
    DialogModule,
    DateRangePickerModule,
    EqualValidator,
  ],
  exports: [
    ...components,
    ...materialModules,
    ...COMMON_COMPONENTS,
    CheckListComponent,
    CommonModule,
    RouterModule,
    NgSelect2Module,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    TooltipModule,
    CustomDateFormatPipe,
    AllowDecimalDirective,
    ConfigDatePipe,
    ConfigTimePipe,
    CodeFormatterPipe,
    ConfigCurrencyPipe,
    NumberSuffixPipe,
    NgxDaterangepickerMd,
    NumbersOnlyDirective,
    ConfirmationDialogDirective,
    TimePickerModule,
    DateRangePickerModule,
    DialogModule,
    OpenModalDirective,
    EqualValidator,
    AlphanumericDirective,
  ],

  providers: [
    CustomDateFormatPipe,
    { provide: DEFAULT_DIALOG_CONFIG, useValue: { hasBackdrop: true } },
  ],
})
export class SharedModule {}
