import { Injectable } from '@angular/core';

import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Idle } from '@ng-idle/core';
import * as checkPasswordStrength from 'check-password-strength';
import * as CryptoTS from 'crypto-ts';
import { ToastrService } from 'ngx-toastr';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';

import { GenericService } from './generic.service';
import { StorageService } from './storage.service';
import { CredentialService } from './credential.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public notify = new Subject<{ option: string; value: any }>();
  /**
   * Observable string streams
   */
  notifyObservable$ = this.notify.asObservable();
  public isShowHeader = true;
  public isShowFooter = true;
  public generalConfigData: any = {};
  isContainSpace = true;

  constructor(
    private toastrService: ToastrService,
    private storageService: StorageService,
    private credentialService: CredentialService,
    private genericService: GenericService,
    private router: Router,
    private idle: Idle
  ) {}

  // Encode Decode URL
  ngEncode(param: string) {
    return window.btoa(unescape(encodeURIComponent(param)));
  }

  ngDecode(param: string) {
    return decodeURIComponent(escape(window.atob(param)));
  }

  // Encrypt Decrypt password
  decryptionAES(msg: any) {
    const bytes = CryptoTS.AES.decrypt(msg, 'meydan secret key');
    const plaintext = bytes.toString(CryptoTS.enc.Utf8);
    return plaintext;
  }

  encryptAES = (msg: any) =>
    CryptoTS.AES.encrypt(msg, 'meydan secret key').toString();

  // Toastr
  public displayError(title: string, message: string = ''): void {
    this.toastrService.error(message, title);
  }

  public displaySuccess(title: string, message: string = '') {
    this.toastrService.success(message, title);
  }

  public displayInfo(title: string, message: string = ''): void {
    this.toastrService.info(message, title);
  }

  public displayWarning(title: string, message: string = ''): void {
    this.toastrService.warning(message, title);
  }

  public downloadFile(url: string, filename: string, trimmed_filename?: string) {
    // console.log(filename)
    // console.log(trimmed_filename)
    const fileUrl = url;
    this.genericService.fileDownaload(fileUrl).subscribe((blob: Blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // Use trimmed_filename if provided, otherwise use filename
      link.download = trimmed_filename || filename;
      // link.download = filename;
      link.click();
    });
  }

  public logout(): void {
    this.idle.stop();
    this.credentialService.setUserDetails(null);
    this.storageService.clearCookie();
    this.router.navigate(['/login']);
  }

  public validatePassword(password: any): any {
    if (password) {
      const passStrength: any =
        checkPasswordStrength.passwordStrength(password);
      if (/\s/g.test(password)) {
        this.isContainSpace = false;
      } else {
        this.isContainSpace = true;
      }
      return { passStrength, isContainSpace: this.isContainSpace };
    }
    return null;
  }

  public validatePasswordRegister(password: any): any {
    if (password) {
      const passStrength: any =
        checkPasswordStrength.passwordStrength(password);
      const isContainSpace = !/\s/g.test(password);
      const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/g.test(password);
      const isMinLength = password.length >= 8;

      return {
        passStrength,
        isContainSpace,
        containsSpecialChar,
        isMinLength,
      };
    }
    return null;
  }

  // Confirm box
  public confirmationDialog(
    title: string,
    text: string = '',
    cnfBtnText?: string
  ): any {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: cnfBtnText ? cnfBtnText : 'Yes!',
      allowOutsideClick: true
    });
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  numberOnlyNoDeciaml(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57 || charCode === 46)) {
      return false;
    }
    return true;
  }

  alphaOnly(event: any) {
    const key = event.keyCode;
    return (key >= 65 && key <= 90) || key === 8 || key === 32;
  }

  // times
  getDayHours(): any {
    const hours = [];
    for (let i = 0; i < 13; i++) {
      hours.push({
        id: i + '',
        text: i + '',
      });
    }
    return hours;
  }

  getDayMinutes(): any {
    const mins = [];
    for (let i = 0; i < 61; i++) {
      mins.push({
        id: i,
        text: i,
      });
    }
    return mins;
  }

  disableDate() {
    return false;
  }

  createParamsURL(payload: { [key: string]: any }): string {
    const url = Object.keys(payload)
      .map((k) => [k, payload[k]].map(encodeURIComponent).join('='))
      .join('&');
    return url;
  }

  getEditorConfig(): AngularEditorConfig {
    return {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText',
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: false,
      toolbarPosition: 'bottom',
      toolbarHiddenButtons: [['insertImage', 'insertVideo', 'fontName']],
    };
  }

  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    const todayDate = `${year}-${month}-${day}`;
    return todayDate;
  }

  getCurrentTime() {
    const d = new Date(),
      h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
      m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    const currentTime = h + ':' + m;
    return currentTime;
  }

  // Page option
  getPageSizeOptions() {
    return [5, 10, 25, 50, 100, 200];
  }

  getRandomId() {
    return Math.floor(Math.random() * 9000000000 + 1000000000);
  }

  convertFormGroupToFormData(formGroup: FormGroup, formValue: any): FormData {
    const formData = new FormData();

    // Loop through form controls and append values to FormData
    Object.keys(formValue).forEach((key) => {
      const control = formGroup.get(key);

      if (control && (control.value || ['', false].includes(control.value))) {
        if (control instanceof File) {
          // Handle file input separately
          formData.append(key, control, control.name);
        } else {
          formData.append(key, control.value);
        }
      }
    });

    return formData;
  }

  /**
   * Notify to all listeners
   * @param data
   */
  public notifyOther(data: { option: string; value: any }) {
    if (data) {
      this.notify.next(data);
    }
  }

  public discountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value !== null && (isNaN(value) || value < 1 || value > 100)) {
        return { invalidDiscount: true };
      }

      return null;
    };
  }
}
