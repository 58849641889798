/* eslint-disable complexity */
export function generateRegExp(
  pattern: RegExp | string | string[]
): RegExp | null {
  if (!pattern) {
    return null;
  }

  if (pattern instanceof RegExp) {
    return new RegExp(pattern);
  } else if (typeof pattern === 'string') {
    return new RegExp(pattern, 'ig');
  } else if (pattern instanceof Array) {
    return new RegExp(`(${pattern.join('|')})`, 'ig');
  }
  return null;
}

export function isValidFile(
  file: File,
  maxSize: number,
  allowedExt: string,
  allowedTypes: string
): {
  validity: boolean;
  fileData: {
    errorType: string;
    fileType: string;
    size: number;
    fileExt: string;
  };
} {
  const extP = generateRegExp(allowedExt);
  const typeP = generateRegExp(allowedTypes ?? '');
  const errors: any = {};

  if (maxSize && maxSize < file.size) {
    errors.fileSize = true;
  }

  if (extP && !extP.test(file.name)) {
    errors.fileExt = true;
  }

  if (typeP && !typeP.test(file.type)) {
    errors.fileType = true;
  }
  const isValid = Object.keys(errors).length === 0;
  const data = {
    errorType: Object.keys(errors)[0],
    fileType: file.type,
    size: file.size / 1000,
    fileExt: file.name.split('.').pop() || '',
  };
  return { validity: isValid, fileData: data };
}

export function getErrorReason(data: {
  errorType: any;
  fileType: string;
  size: number;
  fileExt: string;
}) {
  switch (data.errorType) {
    case 'fileExt':
      return `File extension with '${data.fileExt}' is not allowed`;
    case 'fileSize':
      // return `Too Large (${data.size} KB) must be under 1024 KB`;
      return `File size too large (${(data.size / 1024).toFixed(
        2
      )} MB), must be under 5 MB`;
    case 'fileType':
      return `Only file type 'image' is allowed`;
    default:
      return 'Unable to upload, Please try again';
  }
}
