import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GeneralService, ValidationService } from '@app/core/services';
import { SupportService } from '@app/core/services/support.service';
import { SubscriptionDisposerComponent } from '@app/shared/helpers/subscription-disposer';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-ticket-invoice-request-modal',
  templateUrl: './ticket-invoice-request-modal.component.html',
  styleUrls: ['./ticket-invoice-request-modal.component.scss'],
})
export class TicketInvoiceRequestModalComponent
  extends SubscriptionDisposerComponent
  implements OnInit, OnChanges
{
  ticketID: any;
  chatModalObject: any;
  ticketDetails: any;
  config!: AngularEditorConfig;
  invRequestData = new UntypedFormGroup({
    description: new UntypedFormControl('', [ValidationService.required]),
  });

  constructor(
    public generalService: GeneralService,
    private supportService: SupportService,
    public dialogRef: DialogRef<any>,
    @Inject(DIALOG_DATA)
    public data: any
  ) {
    super();

    this.ticketID = data.ticketID;
    this.chatModalObject = data.chatModalObject;
  }

  get f() {
    return this.invRequestData.controls;
  }

  ngOnInit(): void {
    if (this.ticketID) {
      this.getTicketDetails();
    }
    this.config = {
      ...this.generalService.getEditorConfig(),
      toolbarPosition: 'bottom',
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ticketID) {
      this.getTicketDetails();
    }
    if (changes['ticketDetails'] && changes['ticketDetails'].currentValue) {
      this.ticketDetails = changes['ticketDetails'].currentValue;
      this.ticketDetails = this.ticketDetails;
      this.getTicketDetails();
    }
  }

  getTicketDetails(): void {
    console.log('customer ticket detials')
    const ticketID = this.ticketID;
    this.supportService
      .getTicketDetails(ticketID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          if (res) {
            this.ticketDetails = res?.data;
            // this.ticketDetails?.ticketfiles?.map((item: any) => {
            //   let arr = [];
            //   arr = item.file_name?.split('.');
            //   item.file_type = arr?.[arr.length - 1];
            // });
            console.log('customer ticket details')
            if (this.ticketDetails?.ticketfiles && Array.isArray(this.ticketDetails.ticketfiles)) {
              this.ticketDetails.ticketfiles = this.ticketDetails.ticketfiles.map((item: any) => {
                // Extract the file type from the file name
                console.log('item:', item);

                const arr = item?.file_name.split('.');
                const file_type = arr[arr.length - 1];
                const trimmed_file_name = item?.file_name.replace(/_(?!.*_).*$/, '');
                return {
                  ...item,
                  file_type: file_type, // Add the file_type property to the item
                  trimmed_file_name,
                };
              });
            }
            // console.log('this.ticketDetails.ticketfiles');
            // console.log(this.ticketDetails.ticketfiles);
          }
        },
        (error) => {
          this.generalService.displayError(error?.error?.message);
        }
      );
  }

  sendInvoiceRequest(): void {
    if (this.invRequestData.invalid) {
      this.invRequestData.markAllAsTouched();
      return;
    } else {
      const payload = {
        ticketID: this.ticketID,
        invoice_request_description: this.invRequestData.value.description,
      };
      this.supportService
        .ticketInvoiceRequestSend(payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            if (res) {
              this.generalService.displaySuccess(res?.message);
              this.closeModal();
            }
          },
          (error) => {
            this.generalService.displayError(error?.error?.message);
          }
        );
    }
  }

  closeModal(): void {
    if (this.data?.onModalClose) {
      this.data.onModalClose(); // Call the callback function
    }
    this.dialogRef.close();
  }
}
